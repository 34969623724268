import React from 'react'
import Content from '../components/content'
import titleImg from '../images/bg8.jpg'
import TeamList from '../components/team-list'

const OurTeam = () => (
  <Content title='Our team' titleImg={titleImg} tagline='Passion led us here'>
    <TeamList/>
  </Content>
)

export default OurTeam 
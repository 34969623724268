import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import Collapse from '@material-ui/core/Collapse'
import Avatar from '@material-ui/core/Avatar'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { MDBCol } from 'mdbreact'
import { GatsbyImage } from "gatsby-plugin-image"

const useStyles = makeStyles(theme => ({
  root: {
    background: "linear-gradient(#ddeff2,#bfe2e8)"
  },
  imgParent: {
    textAlign: 'center'
  },
  media: {
    height: 230
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatarBg: {
    backgroundColor: theme.palette.primary.main
  }
}))

export default function MemberCard({role, avatar, title, job, image, qualification, languages, availability, bio}) {
  const classes = useStyles()
  const [expanded, setExpanded] = React.useState(false);
  const avatars = {
    "doctor": "fa-user-md",
    "nurse": "fa-user-nurse",
    "receptionist": "fa-edit",
    "physiotherapist": "fa-hand-paper",
    "dietitian": "fa-seedling"
  }
  const handleExpandClick = () => {
    setExpanded(!expanded);
  }

  return (
    <MDBCol size="4" style={{minWidth: "350px", margin: "15px auto" }}>
      <Card raised className={classes.root}>
        <CardHeader
          avatar={
            <Avatar aria-label="role" className={classes.avatarBg}>
              <i className={`fas ${avatars[avatar]}`}></i>
            </Avatar>
          }
          title={title}
          titleTypographyProps={{variant: 'h6' }}
          subheader={job}
        />
        <div className={classes.imgParent}>
          <GatsbyImage
            className={classes.media} 
            alt={title} 
            image={image}
          />
        </div>
        <CardContent>
          {qualification ?
            <Typography variant="body1" component="p">
              {qualification}
            </Typography> :
            <Typography variant="body1" component="p">
              &nbsp;
            </Typography>}
          <Typography variant="body1" component="p">
            {`Speaks ${languages}`}
          </Typography>  
          <Typography variant="body1" component="p">
            {`Available: ${availability}`}
          </Typography>                            
        </CardContent>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            {/* MuiTypography-root MuiTypography-body1 -> So the expanded text looks the same as overview*/}            
            <div class="MuiTypography-root MuiTypography-body1" dangerouslySetInnerHTML={{ __html: bio }} />
          </CardContent>
        </Collapse>
        <CardActions disableSpacing>
          <Button color="primary" size="small" onClick={handleExpandClick} >
            {expanded ? "Less" : "More"}
          </Button>          
        </CardActions>
      </Card>
    </MDBCol>
  )
}